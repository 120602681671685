import axios from "axios";
import Notiflix, { Confirm } from "notiflix";

window.colors = {
  primary: "#5f76e8",
  secondary: "#64748b",
  success: "#499766",
  warning: "#fb7720",
  danger: "#cb1515",
};
window.Axios = axios;
window.NotiflixJs = Notiflix;
window.confirm = Confirm;
NotiflixJs.Confirm.init({
  borderRadius: ".3rem",
  okButtonBackground: colors.success,
  cancelButtonBackground: "#696969",
});

/**
 * Adds a loading animation inside an element
 * @param {string|HTMLElement} parentElement
 * @param {boolean} backdrop
 */
window.addLoader = (
  parentElement,
  { backdrop = false, color = "#fff", align = "center" } = {}
) => {
  if (!(parentElement instanceof HTMLElement)) {
    parentElement = document.querySelector(parentElement);
  }

  const loader = document.createElement("div");
  loader.classList.add("ripple");
  const inner1 = document.createElement("div");
  const inner2 = document.createElement("div");
  inner1.style.backgroundColor = color;
  inner2.style.backgroundColor = color;
  if (align == "center") {
    loader.style.display = "block";
    loader.style.margin = "auto";
  }
  loader.append(inner1, inner2);

  if (backdrop) {
    const backdrop = document.createElement("div");
    backdrop.className = "loader backdrop";
    backdrop.style.zIndex = 1000001;

    backdrop.append(loader);
    parentElement.append(backdrop);
    return;
  }

  loader.classList.add("loader");
  parentElement.append(loader);
};

/**
 * Removes the animation inside an element
 * @param {string|HTMLElement} parentElement
 */
window.removeLoader = (parentElement) => {
  if (!(parentElement instanceof HTMLElement)) {
    parentElement = document.querySelector(parentElement);
  }

  parentElement.querySelector(":scope > .loader")?.remove();
};

/**
 * Shows server side error messages on forms
 * @param {HTMLFormElement} form - The form object
 * @param {JSON} errors - JSON Response in Laravel's error response format
 */
window.handleSubmitError = (form, response) => {
  // Remove previous error messages and classes
  form.querySelectorAll(".alert-danger").forEach((el) => el.remove());
  form.querySelectorAll(".text-danger").forEach((el) => el.remove());
  form
    .querySelectorAll(".is-invalid")
    .forEach((el) => el.classList.remove("is-invalid"));

  if (response && response.errors) {
    for (const key in response.errors) {
      const input = form.querySelector(`[name="${key}"]`);
      input.classList.add("is-invalid");
      input.insertAdjacentHTML(
        "afterend",
        `<div class="text-danger">${response.errors[key]}</div>`
      );
    }
  } else if (response.message) {
    const alert = document.createElement("div");
    alert.className = "alert alert-danger";
    alert.innerText = response.message;
    form.prepend(alert);
  }
};
